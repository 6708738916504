<template>
<!-- 医护平台 -->
  <div id="app" style="height:100%" v-wechat-title="getThemeTag.title">
    <!-- v-if="$route.path !== '/meau'" -->
    <keep-alive>
      <router-view v-if="isRouterAlive"></router-view>
    </keep-alive>
    <!-- <router-view v-else></router-view> -->
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { getTheme } from '@/api/systemTopic'
import { getThenant } from '@/api/login'
export default {
  name: 'App',

  provide () { // 在祖先组件中通过 provide 提供变量
    return {
      reload: this.reload // 声明一个变量
    }
  },

  data () {
    return {
      isRouterAlive: true, // 控制 router-view 是否显示达到刷新效果
      // requestUrl: process.env.VUE_APP_BASE_URL, // 获取当前请求域名    
      // requestUrl: window.location.protocol + "//" + window.location.host, // 动态获取当前请求域名
      requestUrl: this.$baseApi(), // 配置的域名
      getThemeTag: {
        title: '', // 浏览器标题
        icon: '', // 浏览器图标
      },
    }
  },

  created () {
    this.inGetTheme();
    if (!getToken()) {
      // 用户并未登录 跳转到登录界面
      this.$router.push('/login')
    }
  },

  mounted () {
    window.addEventListener('onbeforeunload ', this.unload)
  },

  methods: {
    unload () {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },

    // provide中声明的变量
    reload () { // 通过 this.isRouterAlive 控制 router-view 达到刷新效果
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },

    async inGetTheme(){
      // 先获取企业id，不然请求不了平台名称的接口
      console.log(this.requestUrl);
      let resArr = await getThenant({authority: this.requestUrl})
      console.log(resArr.data);
      // if(resArr.resultCode === 0){
        let res = await getTheme({tenantId: resArr.data.id})
        this.getThemeTag.title = res.data.platformName
        this.getThemeTag.icon = res.data.webPagePathName
        // console.log(res.data);
        this.$store.dispatch('setThemeDataArrAsync', res.data)
        // 获取浏览器图标
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = res.data.webPagePathName
        document.getElementsByTagName('head')[0].appendChild(link)
      // }
    },
    // inGetTheme(){
    //   // 先获取企业id，不然请求不了平台名称的接口
    //   getThenant({authority: this.requestUrl}).then(resArr => {
    //     if(resArr.resultCode === 0){
    //       getTheme({tenantId: resArr.data.id}).then(res => {
    //         this.getThemeTag.title = res.data.platformName
    //         this.getThemeTag.icon = res.data.webPagePathName
    //         console.log(res.data);
    //         this.$store.dispatch('setThemeDataArrAsync', res.data)
    //         // 获取浏览器图标
    //         var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    //         link.type = 'image/x-icon'
    //         link.rel = 'shortcut icon'
    //         link.href = res.data.webPagePathName
    //         document.getElementsByTagName('head')[0].appendChild(link)
    //       }).catch(error => {
    //         console.log(error);
    //       })
          
    //     }
    //   }).catch(error => {

    //   })
      
    // },
  }
}
</script>

<style lang="less">
@import './css/index.css';
html,body{
  width: 100%;
  height: 100%;
}

html{
  font-size: 0.8333333334vw;
}
</style>
